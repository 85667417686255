import { render, staticRenderFns } from "./AssetTradeModal.vue?vue&type=template&id=6e610fab&scoped=true"
import script from "./AssetTradeModal.vue?vue&type=script&lang=js"
export * from "./AssetTradeModal.vue?vue&type=script&lang=js"
import style0 from "./AssetTradeModal.vue?vue&type=style&index=0&id=6e610fab&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e610fab",
  null
  
)

export default component.exports